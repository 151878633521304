
import { Options, Vue } from 'vue-class-component'
import Link from '@/core/Link.vue'
import Feature from '@/core/Feature.vue'
import FeatureAlt from '@/core/FeatureAlt.vue'

@Options({
  components: { Link, Feature, FeatureAlt }
})
export default class Solution extends Vue {}
