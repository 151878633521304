
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class ThemeSwitcher extends Vue {
  isDark = false

  created() {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')
    if (darkThemeMq.matches) {
      this.switchTheme()
    }
  }

  switchTheme() {
    this.isDark = !this.isDark
    const app = document.querySelector('html')
    if (app) {
      const theme = this.isDark ? 'dark' : 'light'
      app.className = theme
    }
  }
}
