import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative min-h-screen max-w-screen overflow-x-hidden bg-gray-50 dark:bg-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_Solution = _resolveComponent("Solution")!
  const _component_Technology = _resolveComponent("Technology")!
  const _component_WorkWithUs = _resolveComponent("WorkWithUs")!
  const _component_Reference = _resolveComponent("Reference")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_Hero),
    _createVNode(_component_Solution),
    _createVNode(_component_Technology),
    _createVNode(_component_WorkWithUs),
    _createVNode(_component_Reference),
    _createVNode(_component_Contact),
    _createVNode(_component_Footer)
  ]))
}