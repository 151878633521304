
import { Vue, prop } from 'vue-class-component'

class Props {
  title = prop({ default: '' })
  description = prop({ default: '' })
  imgUrl = prop({ default: '' })
  altImgUrl = prop({ default: '' })
  comment = prop({ default: '' })
  commentAuthor = prop({ default: '' })
}
export default class Feature extends Vue.with(Props) {}
