
import { Vue, prop } from 'vue-class-component'
import { copyUrl } from '@/utils/copyUrl'

class Props {
  path = prop({ default: '' })
  alt = prop({ default: false })
}
export default class Link extends Vue.with(Props) {
  copyUrl = copyUrl
}
