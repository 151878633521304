import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["w-12 px-2 font-medium border-r border-gray-600 dark:border-gray-400 rounded-l-md focus:outline-none", {
        'font-bold text-gray-900 dark:text-white': _ctx.lang === 'en',
        'text-gray-700 dark:text-gray-300': _ctx.lang !== 'en'
      }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$i18n.locale = 'en'))
    }, " EN ", 2),
    _createElementVNode("button", {
      class: _normalizeClass(["w-12 px-2 font-medium rounded-r-md focus:outline-none", {
        'font-bold text-gray-900 dark:text-white': _ctx.lang === 'de',
        'text-gray-700 dark:text-gray-300': _ctx.lang !== 'de'
      }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$i18n.locale = 'de'))
    }, " DE ", 2)
  ]))
}