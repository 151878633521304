import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e7b4bea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "work-with-us",
  class: "content-wrapper pt-16"
}
const _hoisted_2 = { class: "content-title" }
const _hoisted_3 = {
  class: "px-6 pt-12 text-gray-600 dark:text-gray-300",
  "data-aos": "fade-up",
  "data-aos-anchor-placement": "center-bottom"
}
const _hoisted_4 = { class: "pt-12 grid grid-cols-1 md:grid-cols-3 gap-4 text-gray-700" }
const _hoisted_5 = { class: "feature-card" }
const _hoisted_6 = { class: "feature-card" }
const _hoisted_7 = { class: "feature-card" }
const _hoisted_8 = { class: "feature-card" }
const _hoisted_9 = { class: "feature-card" }
const _hoisted_10 = { class: "feature-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('workwithus.headline')) + " ", 1),
      _createVNode(_component_Link, { path: "#work-with-us" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.$t('workwithus.description')) + " ", 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('workwithus.feature1')), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('workwithus.feature2')), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('workwithus.feature3')), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('workwithus.feature4')), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('workwithus.feature5')), 1),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('workwithus.feature6')), 1)
      ])
    ])
  ]))
}