
import { Options, Vue } from 'vue-class-component'
import Logo from '@/core/Logo.vue'
import PrivacyDe from '@/components/PrivacyDe.vue'
import PrivacyEn from '@/components/PrivacyEn.vue'

@Options({
  components: { Logo, PrivacyDe, PrivacyEn }
})
export default class Footer extends Vue {
  isPrivacyShown = false

  displayPrivacy() {
    this.isPrivacyShown = true
  }
  closePrivacy() {
    this.isPrivacyShown = false
  }
}
